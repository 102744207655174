import { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";

const DateInput = ({ postToDB, putToDB, initialFieldData, fieldDataExists, readOnly, odooData, hasOdooMapping }) => {
	const [currentDate, setCurrentDate] = useState("");
	const [error, setError] = useState("");

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}

		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentDate(newValue);
	}, [initialFieldData, fieldDataExists, odooData, hasOdooMapping]);

	const validateDate = (date) => {
		const datePattern = /^\d{4}-\d{2}-\d{2}$/;
		if (!date.match(datePattern)) {
			if (date === "") {
				setError("");
				return true;
			} else {
				setError("Invalid date format. Please make sure the month, day, and year are valid.");
				return false;
			}
		}

		const [year, month, day] = date.split("-").map(Number);

		if (year < 1000 || year > 3000) {
			setError("Invalid year. Please use a year between 1000 and 3000.");
			return false;
		}

		if (month < 1 || month > 12) {
			setError("Invalid month. Please use a value between 01 and 12.");
			return false;
		}

		const maxDaysInMonth = new Date(year, month, 0).getDate();
		if (day < 1 || day > maxDaysInMonth) {
			setError(`Invalid day. ${month} has only ${maxDaysInMonth} days.`);
			return false;
		}

		setError("");
		return true;
	};

	const sendToDb = () => {
		if (validateDate(currentDate)) {
			if (fieldDataExists === false && currentDate !== "") {
				postToDB(currentDate);
			} else if (fieldDataExists) {
				putToDB(currentDate);
			}
		}
	};

	const updateDate = (e) => {
		validateDate(e.target.value);
		setCurrentDate(e.target.value);
	};

	const clearDate = (e) => {
		e.preventDefault();
		setCurrentDate("");
		if (fieldDataExists === false && currentDate !== "") {
			postToDB("");
		} else if (fieldDataExists) {
			putToDB("");
		}
	};

	return (
		<form>
			<div className="w-full flex">
				<input
					type="date"
					value={currentDate}
					onChange={updateDate}
					onBlur={() => sendToDb()}
					className="rounded-l-lg border border-2 p-2 border-gray flex-grow"
					readOnly={readOnly}
				/>
				<button onClick={clearDate} className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-r-lg">
					Clear
				</button>
			</div>

			{error ? (
				<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
					<BsExclamationCircleFill color="red" className="text-3xl" />
					<p>{currentDate}</p>
					<p>{error}</p>
				</div>
			) : null}
		</form>
	);
};

export default DateInput;
